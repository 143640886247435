/* ------------------------------Common css start------------------------------ */
.appointment.Pending {
  color: #ffc107 !important;
  font-size: 13px;
  font-weight: 500;
}

.appointment.Complete {
  color: #46bcaa !important;
  font-size: 13px;
  font-weight: 500;
}

.appointment.Reject {
  color: #f5365c !important;
  font-size: 13px;
  font-weight: 500;
}

.searchMain {
  display: flex;
  justify-content: end;
  flex-wrap: wrap;
}

.dashDateSearch .dateSearch .react-daterange-picker__wrapper {
  padding: 0.4rem 1rem 0.4rem 1rem;
  box-shadow: none !important;
  border-radius: 1rem;
  color: #fff;
  font-weight: 600;
  line-height: 1.5px;
  background-color: transparent;
  border: 1px solid #cdcdcd;
  width: max-content;
}

.dashDateSearch input:focus,
.dashDateSearch input:invalid {
  background-color: #f8f9fa;
  outline: none;
}

.dashDateSearch .react-daterange-picker__calendar-button,
.dashDateSearch .react-daterange-picker__clear-button {
  display: none;
}

.dashDateSearch1 .rs-picker-error>.rs-input-group,
.dashDateSearch1 .rs-picker-error>.rs-input-group:focus-within {
  border: 1px solid lightgray !important;
  outline: 0px solid lightgray !important;
  background-color: #f8f9fa !important;
  border-radius: 1rem !important;
  color: #323232 !important;
}

.dashDateSearch1 .rs-picker-daterange .rs-input-group-addon .rs-btn-close {
  display: none !important;
}

.dashDateSearch1 .rs-picker-daterange>.rs-input-group.rs-input-group-inside .rs-input {
  border-radius: 1rem !important;
  background-color: #f8f9fa !important;
}

.dashDateSearch1 .rs-input-group .rs-form-control-wrapper~.rs-input-group-addon,
.rs-input-group input.rs-input~.rs-input-group-addon {
  background-color: #f8f9fa !important;
  border-radius: 1rem !important;
}

.dashDateSearch1 .rs-input-group.rs-input-group-inside .rs-input-group-addon {
  padding: 0px 0px !important;
}

.dashDateSearch1 .rs-input-group.rs-input-group-inside {
  border: 1px solid lightgray !important;
  outline: 0px solid lightgray !important;
  background-color: #f8f9fa !important;
  border-radius: 1rem !important;
  color: #323232 !important;
}

.dashDateSearch1 .datePickerSearch .rs-btn-link:focus,
.rs-btn-link:hover {
  text-decoration: none !important;
}

.userStatus {
  border-radius: 20px;
  display: inline-block;
  font-size: 12px !important;
  font-weight: 300;
  line-height: 1;
  padding: 9px 15px;
}

.userStatus.active {
  /* color: palegoldenrod !important; */
  background-color: #edf8f729 !important;
  color: #46bcaa !important;
  font-size: 13px;
  font-weight: 500;
}

.userStatus.in-active {
  background-color: #feeee93b !important;
  color: red !important;
  font-size: 13px;
  font-weight: 500;
}

.custom-upload .ant-upload button div {
  color: white !important;
}

.custom-upload .ant-upload .anticon {
  color: white !important;
}

/*  image css */
.image-container {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.icon-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.3s;
  pointer-events: none;
}

.image-container:hover .icon-overlay {
  opacity: 1;
}

.image-container img {
  display: block;
  transition: filter 0.3s ease;
}

.image-container:hover img {
  filter: brightness(0.4);
  /* Darken the image */
}

.icon-overlay i {
  font-size: 2rem;
  color: white;
}

/* image css  */

.custom-floating-label label {
  color: white !important;
}

.custom-floating-label label::after {
  background-color: transparent !important;
  color: white !important;
}

.dropdown-toggle::after {
  background-color: transparent !important;
  color: white !important;
}

.back-page {
  min-height: 93vh;
  background-color: #070d0e;
}

.monthWise-datepicker {
  z-index: -10;
  border-radius: 10px;
  padding: 7px;
  border: 1px solid grey;
}

.mobile-dropdown-menu {
  display: none;
}

.mobile-toggle-menu-profile-logout {
  display: none;
}

.dropdown-menu {
  --bs-dropdown-link-active-bg: transparent !important;
}

.status-select {
  color: white !important;
  /* --bs-form-select-bg-img: url('/Assets/images/angle-down-solid.svg') */
}

.userStatusinactive {
  color: #f5365c !important;
  font-size: 13px;
  font-weight: 500;
}

.userStatusactive {
  color: #46bcaa !important;
  font-size: 13px;
  font-weight: 500;
}

.quantity-search:disabled {
  background-color: transparent !important;
}

.readAll {
  background-color: initial;
  border: 1px solid #6c5dd3;
  border-radius: 10px;
  padding: 2px 5px;
  text-align: end;
}

/* Notification Color css Start */
.WalletAddressRequest {
  background-color: #bc7fcd5d;
  border: 1px solid #c700ff5d;
  border-radius: 8px;
  padding: 5px;
}

.WithdrawalRequest {
  background-color: #dd818154;
  border: 1px solid #b9959575;
  border-radius: 8px;
  padding: 5px;
}

.statusChange {
  background-color: #177a4c;
  border: 1px solid #fff;
  border-radius: 10px;
  color: #fff;
  font-size: 11px;
  font-weight: 500;
  height: 17px;
  line-height: 9px;
  min-width: 17px;
  padding: 3px;
  position: absolute;
  right: -5px;
  text-align: center;
  top: -6px;
}

.dashNotiIcon {
  font-size: 1rem;
  animation: dashBell 1s infinite ease;
  transform: rotate(0deg);
  display: flex;
  align-items: center;
  justify-content: center;
}

.dashNotiIcon1 {
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-dash:hover {
  background-color: #ffffff2c;
}

@keyframes dashBell {
  0% {
    transform: rotate(35deg);
  }

  12.5% {
    transform: rotate(-30deg);
  }

  25% {
    transform: rotate(25deg);
  }

  37.5% {
    transform: rotate(-20deg);
  }

  50% {
    transform: rotate(15deg);
  }

  62.5% {
    transform: rotate(-10deg);
  }

  75% {
    transform: rotate(5deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

/* Notification Color css End */

/* ------------------------------Common css end------------------------------ */

/* pagination css start */

.wellnessPagination {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0;
  margin: 0;
}

.wellnessPagination a {
  display: block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  color: white !important;
  border: 1px solid var(--dark-text);
  background-color: var(--secondary-text-color);
  text-decoration: none !important;
  margin: 5px;
  border-radius: 10px;
}

.wellnessPagination a:hover,
.wellnessPagination .paginationActive a {
  background-color: #6c757d;
  border-color: #6c757d;
}

.react-tel-input .form-control {
  width: 100% !important;
}

/* pagination css end */

/* ------------------------------Top bar start------------------------------ */
.topbar {
  position: fixed;
  top: 0;
  left: 250px;
  right: 0;
  height: 60px;
  background: #fff;
  border-bottom: 1px solid rgb(228 228 228 / 0%);
  z-index: 10;
  -webkit-box-shadow: 0 0 2rem 0 rgba(136, 152, 170, .15);
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, .15);
  background-color: #12181a;
  border-bottom: 1px solid rgb(237 237 237 / 12%);
  box-shadow: 0 0.1rem 0.7rem rgb(0 0 0 / 20%)
}

.topbar .navbar {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.user-img {
  width: 35px;
  height: 34px;
  border-radius: 50%;
  border: 0 solid #e5e5e5;
  padding: 0;
}

.search-input {
  color: #c0c8d1;
  background: #141D2D;
  border: 1px solid rgb(255 255 255 / 34%);
  padding: .375rem 3rem .375rem 1rem;
  border-radius: 5px;
  width: 100%;
}

.search-input::placeholder {
  color: #e4e5e6 !important;
  opacity: .5 !important
}

.search-input:focus {
  box-shadow: 0 0 0 .25rem rgba(255, 255, 255, .25);
  background: #141D2D;
}

.user-box {
  border-left: 1px solid rgb(255 255 255 / .15);
  border-right: 1px solid rgb(255 255 255 / .15);
}

.custom-dropdown-menu {
  background-color: #12181a;
  border: none;
}

.custom-dropdown-menu .dropdown-item {
  color: #fff;
}

.custom-dropdown-menu .dropdown-item:hover {
  background-color: #1c2427;
  color: #fff;
}

/* ------------------------------Top bar end------------------------------ */

/* ------------------------------Side Bar Start------------------------------ */
.sidebar-wrapper {
  width: 250px;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  border-right: 0 solid #e4e4e4;
  z-index: 11;
  transition: all .2s ease-out;
  background: #12181a;
  border-right: 1px solid rgb(255 255 255 / 12%);
  box-shadow: 0 0.1rem 0.7rem rgb(0 0 0 / 20%);
}

.sidebar-wrapper .metismenu {
  background: 0 0;
  display: flex;
  padding: 10px;
  margin-top: 60px;
  flex-direction: column
}

.sidebar-wrapper .metismenu li+li {
  margin-top: 5px
}

.sidebar-wrapper .metismenu a {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: left;
  padding: 6px 16px;
  font-size: 15px;
  color: #fff;
  outline-width: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  letter-spacing: .5px;
  border-radius: 0.25rem;
  transition: all .3s ease-out
}

.sidebar-wrapper .metismenu a .parent-icon {
  font-size: 24px;
  line-height: 1
}

.logo {
  padding: 3px 20px;
}

.logo-icon {
  width: 168px;
  margin-bottom: 29px;
}


a.active {
  background: linear-gradient(180deg, #B01F29 0%, #3A64AF 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0px 4px 6px rgba(176, 31, 41, 0.5), 0px -2px 4px rgba(58, 100, 175, 0.5);
}

.metismenu li:hover {
  background-color: rgba(128, 128, 128, 0.199);
  border-radius: 10px;
}

a.active img,
a.active svg {
  filter: drop-shadow(0px 4px 6px rgba(176, 31, 41, 0.5)) drop-shadow(0px -2px 4px rgba(58, 100, 175, 0.5));
}

.mobile-toggle-menu {
  display: none;
  font-size: 26px;
  color: #404142;
  cursor: pointer
}

.mobile-toggle-menu {
  color: #e4e5e6
}

.wrapper.toggled .overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #000;
  opacity: .6;
  z-index: 10;
  display: block;
  cursor: move;
  transition: all .2s ease-out
}

/* ------------------------------Side Bar End------------------------------ */

/* ------------------------------Footer css start------------------------------ */
.page-footer {
  color: #fff;
  left: 250px;
  right: 0;
  bottom: 0;
  position: fixed;
  text-align: center;
  padding: 7px;
  font-size: 14px;
  border-top: 1px solid #e4e4e4;
  z-index: 3
}

.page-footer {
  background-color: #12181a;
  border-top: 1px solid rgb(237 237 237 / 12%)
}

.wrapper.toggled .page-footer {
  left: 70px
}


/* ------------------------------Footer css end------------------------------ */

/* ------------------------------Home Page Css start------------------------------ */
.login-text a {
  color: #fff;
  font-weight: 500;
  transition: all .5s ease-in-out;
  font-size: 14px;
}

.form-control {
  background-color: transparent;
}

.login-submit-btn {
  font-size: 16px;
  line-height: 1.6;
  color: #fff;
  padding: 13px 20px;
  transition: all .5s;
  text-transform: capitalize;
  border-radius: 10px;
  font-weight: 500;
  cursor: pointer;
  text-align: center;
  overflow: hidden;
  border: none;
  background: #414346 !important;
  box-shadow: 0 3px 24px #0000001f;
  opacity: 1;
}

.Err-msg {
  color: red !important;
}

.bg-gradient-ohhappiness {
  background: #00b09b;
  background: -webkit-linear-gradient(45deg, #00b09b, #96c93d) !important;
  background: linear-gradient(45deg, #00b09b, #96c93d) !important;
}

.bg-gradient-ibiza {
  background: #ee0979;
  background: -webkit-linear-gradient(45deg, #ee0979, #ff6a00) !important;
  background: linear-gradient(45deg, #ee0979, #ff6a00) !important;
}

.bg-gradient-moonlit {
  background: linear-gradient(to right, #0f2027, #203a43, #2c5364) !important
}

.border-m {
  border: 3px solid transparent;
  border-image: linear-gradient(180deg, #B01F29 0%, #3A64AF 100%);
  border-image-slice: 1;
  padding: 25px;
}

.card {
  background-color: #040E20;
  box-shadow: 0 0.1rem 0.7rem rgb(0 0 0 / 20%)
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

/* -----Transaction table start----- */
.transaction-table {
  --bs-table-bg: transparent;
  --bs-table-striped-color: #e4e5e6;
  --bs-table-striped-bg: rgba(255, 255, 0255, 0.05);
  --bs-table-active-color: #e4e5e6;
  --bs-table-active-bg: rgba(255, 255, 255, 0.1);
  --bs-table-hover-color: #e4e5e6;
  --bs-table-hover-bg: rgba(255, 255, 255, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: #e4e5e6;
  vertical-align: top;
  border-color: rgb(255 255 255 / 12%)
}

.transaction-table thead th {
  border-bottom: 2px solid rgb(255 255 255 / 12%)
}

.transaction-table>tbody>tr {
  background: #070a10 !important;
}

.transaction-table td,
.transaction-table th {
  text-align: center;
}

/* -----Transaction table end----- */

/* ------------------------------Home Page Css end------------------------------ */

/* ------------------------------Withdrawal Page Css start------------------------------ */
.withdrawal-active-btn {
  background: linear-gradient(180deg, #B01F29 0%, #3A64AF 100%);
  color: white;
  border: none;
  padding: 7px 10px;
  border-radius: 5px;
}

.withdrawal-btn-group button {
  background-color: transparent;
  border: none;
  color: white;
}

.box-l {
  background: #00000047;
  padding: 25px;
  border-radius: 5px;
  height: 274px;
}

.eden-search,
.quantity-search {
  border: 1px solid #7a7f88;
  background: #141D2D;
  color: #fff !important;
}

.eden-search::placeholder {
  color: #7a7f88;
  font-size: 15px;
}

.quantity-search::placeholder {
  color: #7a7f88;
  font-size: 15px;
}

.eden-search:focus {
  border: 1px solid #7a7f88;
  background: #141D2D;
  color: #fff !important;
}

.quantity-search:focus {
  border: 1px solid #7a7f88 !important;
  background: #141D2D !important;
  color: #fff !important;
}

.quantity-search {
  border: 1px solid #7a7f88;
  background: #141D2D;
  color: #fff !important;
}

.profile-telephone input {
  background-color: #141D2D !important;
  color: white !important;
  font-weight: bold;
  font-size: 16px;
}

.profile-telephone .flag-dropdown div {
  background-color: #141D2D !important;
  color: grey !important;
}

.logout-dropdown .dropdown-toggle::after {
  color: white !important;
}

/* ------------------------------Withdrawal Page Css end------------------------------ */

/* ------------------------------Profile Page Css start------------------------------ */
.box {
  margin-bottom: 18px;
}

.profileBorder {
  position: absolute;
  width: .3px;
  height: 100%;
  background: #686767;
  right: auto;
  left: -10%;
}

/* ------------------------------Profile Page Css end------------------------------ */

/* -----user details table start----- */
.user-details-table {
  --bs-table-bg: transparent;
  --bs-table-striped-color: #e4e5e6;
  --bs-table-striped-bg: rgba(255, 255, 0255, 0.05);
  --bs-table-active-color: #e4e5e6;
  --bs-table-active-bg: rgba(255, 255, 255, 0.1);
  --bs-table-hover-color: #e4e5e6;
  --bs-table-hover-bg: rgba(255, 255, 255, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: #e4e5e6;
  vertical-align: top;
  border-color: rgb(255 255 255 / 12%)
}

.user-details-table thead th {
  border-bottom: 2px solid rgb(255 255 255 / 12%)
}

.user-details-table>tbody>tr {
  background: #070a10 !important;
}

/* -----user details table end----- */

.active-user-list td {
  vertical-align: middle;
}

.de-active-user-list td {
  vertical-align: middle;
}