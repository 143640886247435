@media screen and (max-width: 2000px) {
  .dash-logo {
    margin: 0px 0px 120px 0px !important;
  }
}

@media screen and (max-width:1024px) {
  .topbar {
    left: 0 !important
  }

  .sidebar-wrapper {
    left: -300px;
    box-shadow: none
  }

  .wrapper.toggled .sidebar-wrapper {
    left: 0
  }

  .page-footer {
    left: 0
  }

  .mobile-toggle-menu {
    display: block
  }

  .wrapper.toggled .overlay {
    background: #fff;
    opacity: .4
  }

  .LayoutDiv {
    padding: 60px 0px 0px 0px;
  }
}

@media screen and (max-width: 991px) {
  .dashboard-main {
    height: 100% !important;
  }
}

@media screen and (max-width: 767px) {
  .referral-heading {
    text-align: start !important;
  }

  .box label {
    margin-left: 20px;
    margin-bottom: 10px;
  }

  .profileBorder {
    position: absolute;
    display: none
  }

  .profile-main {
    height: 100% !important;
  }

  .password-div{
    margin-bottom: 40px;
  }
}

@media screen and (max-width:575px) {
  .searchMain {
    display: flex;
    justify-content: end;
  }
}

@media screen and (max-width: 425px) {
  .logo-img {
    width: 150px;
    height: 110px;
  }

  .min-vh-100 {
    min-height: 55vh !important;
  }

  .logo {
    padding: 0px;
    margin: 25px 0px 0px 0px !important;
  }

  .amount-text {
    font-size: 14px;
  }

  .date-title {
    display: inline;
  }

  .login-text {
    margin: 20px 0px 0px 0px !important;
  }

  .login-left-side {
    min-height: 36vh !important;
  }

  .welcome-text {
    font-size: 24px !important;
    margin-top: 10px;
  }

  .more-text {
    font-size: 17px !important;
    margin-top: 10px;
  }

  .topbar .navbar {
    padding-left: 5px;
    padding-right: 5px;
  }

  .mobile-toggle-menu {
    margin-left: 10px !important;
  }

  .dash-logo {
    margin: 39px 0px !important;
  }

  .wellnessPagination a {
    margin: 0px !important;
  }
}

@media screen and (max-width: 375px) {
  .dash-logo {
    margin: 33px 0px !important;
  }
}

@media screen and (max-width: 324px) {
  .dropdown-toggler {
    font-size: 23px;
  }

  .navbar.navbar-expand {
    gap: 1px !important;
  }

  .user-img {
    height: 35px !important;
    width: 35px !important;
  }

  .amount-text {
    font-size: 14px;
    text-align: end;
    margin: 0px 0px 0px 5px;
  }

  .welcome-text {
    font-size: 23px !important;
    margin-top: 0px;
  }

  .more-text {
    font-size: 16px !important;
    margin: 4px 0px 0px 0px !important;
  }

  .topbar .navbar {
    padding-left: 0px;
    padding-right: 0px;
  }

  .mobile-toggle-menu {
    margin-left: 10px !important;
  }

  .dash-logo {
    margin: 33px 0px !important;
  }
}